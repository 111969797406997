import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { ref, getCurrentInstance } from 'vue';

import DashboardLayout from "../views/dashboard/layout/DashboardLayout.vue";
import AuthLayout from "../views/dashboard/pages/AuthLayout.vue";

// Page Headers
import DashboardHeader from "../views/dashboard/dashboard/DashboardHeader.vue";
import DefaultHeader from "../views/dashboard/headers/DefaultHeader";
import SweetAlertHeader from "../views/dashboard/headers/SweetAlertHeader";
import VectorMapsHeader from "../views/dashboard/headers/VectorMapsHeader";
import CalendarHeader from "../views/dashboard/headers/CalendarHeader";

// GeneralViews
import NotFound from "../views/generalViews/NotFoundPage.vue";

// Dashboard pages
import Dashboard from "../views/dashboard/dashboard/Vnud-Dashboard.vue";
import Widgets from "../views/dashboard/dashboard/Vnud-Widgets.vue";
import Charts from "../views/dashboard/dashboard/Vnud-Charts.vue";
import Calendar from "../views/dashboard/dashboard/Vnud-Calendar.vue";

// Pages
import Timeline from "../views/dashboard/pages/Vnud-Timeline.vue";
import UserProfile from "../views/dashboard/pages/UserProfile.vue";

import globalController from "@/globalController.js"

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: UserProfile, header: DefaultHeader },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: Timeline, header: DefaultHeader },
    },
  ],
};

// Components pages
import Buttons from "../views/dashboard/components/Vnud-Buttons.vue";
import GridSystem from "../views/dashboard/components/GridSystem.vue";
import Panels from "../views/dashboard/components/Vnud-Panels.vue";
import SweetAlert from "../views/dashboard/components/SweetAlert.vue";
import Notifications from "../views/dashboard/components/Vnud-Notifications.vue";
import Icons from "../views/dashboard/components/Vnud-Icons.vue";
import Typography from "../views/dashboard/components/Vnud-Typography.vue";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons, header: DefaultHeader },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem, header: DefaultHeader },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels, header: DefaultHeader },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert, header: SweetAlertHeader },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications, header: DefaultHeader },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons, header: DefaultHeader },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography, header: DefaultHeader },
    },
  ],
};

// Form Pages
import RegularForms from "../views/dashboard/forms/RegularForms.vue";
import StockRegister from "../views/dashboard/stock/StockRegister.vue";
import CompanyView from "../views/dashboard/company/CompanyView.vue";
import CompanyExplore from "../views/dashboard/company/CompanyExplore.vue";
import CompanyNews from "../views/dashboard/company/CompanyNews.vue";
import ExtendedForms from "../views/dashboard/forms/ExtendedForms.vue";
import ValidationForms from "../views/dashboard/forms/ValidationForms.vue";
import Wizard from "../views/dashboard/forms/Vnud-Wizard.vue";
import NewsExploration from "../views/dashboard/news/NewsExploration.vue";

let stocksMenu = {
  path: "/stock",
  component: DashboardLayout,
  redirect: "/stock/register",
  name: "Stock",
  children: [
    {
      path: "register",
      name: "Watch a company",
      components: { default: StockRegister, header: DefaultHeader },
    },
  ],
};

let newsMenu = {
  path: "/news",
  component: DashboardLayout,
  redirect: "/news/bullish",
  name: "News",
  children: [
    {
      path: "latest",
      name: "Latest news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?status=INDEXED&order_by=-creation_date&creation_date__gte=1 hour&" },
      },
    },
    {
      path: "bullish",
      name: "Bullish news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?sentiment_score__0__gte=7&order_by=-creation_date&creation_date__gte=1 day&" },
      },
    },
    {
      path: "bearish",
      name: "Bearish news",
      components: { default: NewsExploration, header: DefaultHeader },
      props: {
        default: { apicall: "/news/query?sentiment_score__0__lte=-1&order_by=-creation_date&creation_date__gte=1 day&" },
      },
    },
  ],
};

let portfolioMenu = {
  path: "/portfolio",
  component: DashboardLayout,
  redirect: "/portfolio/register",
  name: "Track Purchase",
  children: [
    {
      path: "register",
      name: "Track a shares purchase",
      components: { default: StockRegister, header: DefaultHeader },
    },
  ],
};


let companyMenu = {
  path: "/company",
  component: DashboardLayout,
  redirect: "/company/view",
  name: "Company View",
  children: [
    {
      path: "explore",
      name: "Company exploration",
      components: { default: CompanyExplore, header: DefaultHeader },
    },
    {
      path: "view",
      name: "Company view",
      components: { default: CompanyView, header: DefaultHeader },
    },
    {
      path: "news",
      name: "Company recent news",
      components: { default: CompanyNews, header: DefaultHeader },
    },
  ],
};


let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms, header: DefaultHeader },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms, header: DefaultHeader },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard, header: DefaultHeader },
    },
  ],
};

// Tables Pages
import RegularTables from "../views/dashboard/tables/RegularTables.vue";
import ExtendedTables from "../views/dashboard/tables/ExtendedTables.vue";
import PaginatedTables from "../views/dashboard/tables/PaginatedTables.vue";

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables, header: DefaultHeader },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables, header: DefaultHeader },
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      components: { default: PaginatedTables, header: DefaultHeader },
    },
  ],
};

// Maps Pages
import GoogleMaps from "../views/dashboard/maps/GoogleMaps.vue";
import FullScreenMap from "../views/dashboard/maps/FullScreenMap.vue";
import VectorMaps from "../views/dashboard/maps/VectorMaps.vue";

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps, header: DefaultHeader },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps, header: VectorMapsHeader },
    },
  ],
};

// Auth Pages
import Pricing from "../views/dashboard/pages/Vnud-Pricing.vue";
import Lock from "../views/dashboard/pages/Vnud-Lock.vue";
import Register from "../views/dashboard/pages/Vnud-Register.vue";
import Login from "../views/dashboard/pages/Vnud-Login.vue";

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  newsMenu,
  stocksMenu,
  portfolioMenu,
  companyMenu,
  authPages,
  pagesMenu,
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard, header: DashboardHeader },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar, header: CalendarHeader },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts, header: DefaultHeader },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets, header: DefaultHeader },
      },
    ],
  },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

const publicPaths = ['/lock', '/login', '/register', '/forgot-password', '/pricing'];


// Add the global navigation guard
router.beforeEach((to, from, next) => {

  const token = localStorage.getItem("userToken");
  // TODO: Add to be able to go to an specific route if the user hasn't logged in.
  if (publicPaths.includes(to.path)) {
    return next();
  }

  if (!token && !publicPaths.includes(to.path)) {
    let username = localStorage.getItem("userName");
    if (username) {
      console.log(" LOCK ");
      next("/lock");
    } else {
      console.log(" LOGIN ");
      next("/login");
    }
  } else {
    console.log("  USER ");
    globalController.get_currentuser(
      (user) => {
        console.log(" NEXT VIEW " + to.path);
        next();
      },
      (error) => {
        console.log("INDEX: Error loading user");
      },
      true // Invalidate when we change view
    )
  }
});

export default router;

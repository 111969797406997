<template>
  <div class="row">

    <div class="col-md-12">
      <time-line v-if="news_articles.length > 0">
        <time-line-item v-for="news in news_articles " :key="news" inverted
          :badge-type="getBadgeClassType(news.sentiment_score)" :badge-icon="getBadgeNowIcon(news.sentiment_score)">

          <template v-slot:header>
            <div class="pull-right" v-for="tickers in news.related_exchange_tickers " :key="tickers">
              <n-button v-if="this.$route.query.symbol != tickers" @click="gotoTicker(tickers)" type="primary"
                size="sm"><small>{{ tickers }}</small></n-button>
            </div>

            <span :class="getBadgeClass(news.sentiment_score) + ' pointer'" @click="openLink(news)" >
              {{ news.title }}</span>
          </template>

          <template v-slot:content>
            <p v-html="markdown(news.ia_summary)">
            </p>
            <p v-if="!news.ia_summary">
              {{ news.articles[0] }}
            </p>
          </template>

          <template v-slot:footer>
            <h6>
              <i class="ti-time"></i>
              {{ timeAgo(news.creation_date) }}
            </h6>
          </template>

        </time-line-item>
      </time-line>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from "@/components";

import globalController from "@/globalController.js";
import utils from "@/globalUtil.js";
import { cleanData } from "jquery";

export default {
  components: {
    TimeLine,
    TimeLineItem,
  },
  props: {
    symbol: String,
    apicall: String,
  },
  created() {
    this.loadData();
  },
  data() {
    return {
      ticker: "",
      isLoaded: false,
      news_articles: [],
    };
  },
  methods: {
    gotoTicker(ticker) {
      console.log(" TICKER " + ticker);
      this.$router.push("/company/view?symbol=" + ticker);
    },
    getSentimentScore(scores) {
      // Check if scores is a ProxyArray or regular array and has at least one element
      if (Array.isArray(scores) && scores.length > 0) {
        return scores[0];
      }
      return 0; // Default value if the array is empty or not valid
    },
    getScoreFromArray(score_array) {
      let score = 0;
      try {
        score = score_array[0];
      }
      catch (err) {
        console.log(err);
      }

      return score;
    },
    openLink(article) {
      window.open(article.link);
    },
    getBadgeClassType(score_array) {
      if (!score_array)
        return 'success';

      let score = this.getScoreFromArray(score_array);
      if (score <= -5) {
        return 'danger';
      } else if (score > -5 && score < 0) {
        return 'warning';
      } else if (score === 0) {
        return 'info';
      } else if (score > 0 && score < 5) {
        return 'primary';
      }

      return 'info';
    },
    getBadgeClass(score_array) {
      return 'badge badge-' + this.getBadgeClassType(score_array);
    },
    getBadgeIcon(score_array) {
      if (!score_array)
        return 'business_briefcase-24';
      let score = this.getScoreFromArray(score_array);

      if (score == 0) return 'business_briefcase-24';

      if (score < -7) return 'sport_user-run';
      if (score < -5) return 'design_scissors';
      if (score < 0) return 'objects_umbrella-13';
      if (score > 1) return 'ui-2_like';
      if (score > 3) return 'sport_trophy';
      if (score > 5) return 'ui-2_favourite-28';
      if (score > 7) return 'objects_diamond';

      return 'business_briefcase-24';
    },
    getBadgeNowIcon(score_array) {
      return "now-ui-icons " + this.getBadgeIcon(score_array);
    },
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    timeAgo(timestamp) {
      const now = new Date().getTime(); // Current time in milliseconds
      const timeDifference = now - timestamp * 1000; // Convert timestamp to milliseconds

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);
      const years = Math.floor(days / 365);

      if (seconds <= 1) return `a second ago`;

      if (seconds < 60) return `${seconds} seconds ago`;

      if (minutes == 1) return `${minutes} minute ago`;
      if (minutes < 60) return `${minutes} minutes ago`;

      if (hours == 1) return `${hours} hour ago`;
      if (hours < 24) return `${hours} hours ago`;

      if (days == 1) return `${days} day ago`;
      if (days < 7) return `${days} days ago`;

      if (weeks == 1) return `a week ago`;
      if (weeks < 4) return `${weeks} weeks ago`;

      if (months == 1) return `a months ago`;
      if (months < 12) return `${months} months ago`;

      if (months < 24) return `a year ago`;
      return `${years} years ago`;
    },

    async loadData(company_name) {
      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
      } else {
        apiCall = "/news/query?status=INDEXED&order_by=-creation_date&creation_date__gte=1%20day&";

        let l = apiCall.length;

        if (queryParams.symbol)
          apiCall += "related_exchange_tickers=" + encodeURIComponent(queryParams.symbol);
        else
          apiCall += "related_exchange_tickers=" + encodeURIComponent(this.symbol);

        // We don't have anything to load
        if (apiCall.length == l)
          return;
      }

      globalController.api_call(
        apiCall,
        (result) => {
          this.isLoaded = true;
          if (result.news.length == 0) {
            this.news_articles = [];
          } else
            this.news_articles = result.news;

          console.log("Company result: Result ");
        },
        (error) => {
          console.log("Failed loading: Error loading suggestion");
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      );
    },
  }
};
</script>
<style></style>
